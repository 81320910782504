<template>
    <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.cabinet')"></ProfileNavbar>
        <div class="mob-menu-title">
            {{ $t('sidebar.cabinet') }}
        </div>
        <div class="clearfix">
            <div class="col-md-12">
                <div class="container">
                    <h1 class="cabinet-page-title">{{$t("profile.cabinet-page.title")}}</h1>
                    <div class="page-links">
                        <div class="row col-md-12 blocks">
                            <div class="col-md-4 page-block ">
                                <div class="block-content">
                                    <p class="block-title page-cabinet">
                                        <router-link tag="a" to="/cabinet" class="block-title" :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.cabinet') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.cabinet-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-profile">
                                        <router-link tag="a" to="/profile" class="block-title" :class="{'active': $route.path === '/profile'}">{{ $t('sidebar.profile') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.profile-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-parent">
                                        <router-link tag="a" to="/parent" class="block-title" :class="{'active': $route.path === '/parent'}">{{ $t('sidebar.parent') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.parent-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row col-md-12 blocks">
                            <!-- <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-schools-list">
                                        <router-link tag="a" to="/cabinet" class="block-title" :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.schools-list') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.schools-list-page-subtitle')}}
                                    </p>
                                </div>
                            </div> -->
                            <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-testing">
                                        <router-link tag="a" to="/testing" class="block-title" :class="{'active': $route.path === '/testing'}">{{ $t('sidebar.testing') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.testing-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-notifications">
                                        <router-link tag="a" to="/notification" class="block-title" :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.notifications-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-4 page-block">
                                <div class="block-content">
                                    <p class="block-title page-notes">
                                        <router-link tag="a" to="/notes" class="block-title" :class="{'active': $route.path === '/notes'}">{{ $t('sidebar.notes') }}</router-link>
                                    </p>
                                    <p class="block-subtitle">
                                        {{$t('profile.cabinet-page.list.notes-page-subtitle')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 blocks">
                          <div class="col-md-4 page-block">
                            <div class="block-content">
                              <p class="block-title page-results">
                                <router-link tag="a" to="/results" class="block-title" :class="{'active': $route.path === '/results'}">{{ $t('sidebar.results') }}</router-link>
                              </p>
                              <p class="block-subtitle">
                                {{$t('profile.cabinet-page.list.results-page-subtitle')}}
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4 page-block">
                            <div class="block-content">
                              <p class="block-title page-surveys">
                                <router-link tag="a" to="/surveys" class="block-title" :class="{'active': $route.path === '/surveys'}">{{ $t('sidebar.surveys') }}</router-link>
                              </p>
                              <p class="block-subtitle">
                                {{$t('profile.cabinet-page.list.surveys-page-subtitle')}}
                              </p>
                            </div>
                          </div>

                          <div class="col-md-4 page-block">
                              <div class="block-content">
                                  <p class="block-title page-settings">
                                      <router-link tag="a" to="/settings" class="block-title" :class="{'active': $route.path === '/settings'}">{{ $t('sidebar.settings') }}</router-link>
                                  </p>
                                  <p class="block-subtitle">
                                      {{$t('profile.cabinet-page.list.settings-page-subtitle')}}
                                  </p>
                              </div>
                          </div>
                        </div>
                    </div>

                    <div class="helps">
                        <div class="instruction">
                            <div class="close">
                                <img src="/images/profile/close-instruction.svg" alt="">
                            </div>
                            <p class="instruction-subtitle" v-html="$t('profile.cabinet-page.list.instruction-title')"></p>
                            <div class="video" @click="showModal = true">
                                <img class="video-background" src="/images/profile/instruction.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div id="app">
                        <div v-if="showModal">
                            <transition name="modal">
                                <div class="modal-mask">
                                    <div class="modal-wrapper">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" @click="showModal = false">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="embed-responsive embed-responsive-16by9">
                                                        <iframe class="embed-responsive-item" src="/files/instruction.mp4" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ProfileNavbar from "@/components/ProfileNavbar";

    export default {
        name: 'Cabinet',
        components: {
            ProfileNavbar
        },
        data() {
            return {
                showModal: false
            }
        }
    }
</script>
<style scoped>
 @media screen and (max-width:767px){
   .blocks{
     padding-right: 0!important;
   }
   .page-block{
     padding-right: 0 !important;
   }
 }
 @media screen and (max-width: 622px) {
   .cabinet-page-title{
     margin-top: 4rem;
   }
 }
</style>

<style>

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .page-cabinet {
        padding-left: 25%;
        background-image: url("/images/profile/sidebar-icons/cabinet.svg");
        background-repeat: no-repeat;
        background-position: 12% center;
    }

    .page-profile {
        padding-left: 25%;
        background-image: url("/images/profile/sidebar-icons/profile.svg");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 12% center;
    }
    .page-parent {
        padding-left: 25%;
        background-image: url("/images/profile/sidebar-icons/parent.svg");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 12% center;
    }
    .page-notes {
      padding-left: 25%;
      background-image: url("/images/profile/sidebar-icons/notes.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: 12% center;
    }
    .page-results {
      padding-left: 25%;
      background-image: url("/images/profile/sidebar-icons/results.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: 12% center;
    }
    .page-testing {
        padding-left: 25%;
        background-image: url("/images/profile/sidebar-icons/testing.svg");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 12% center;
    }
    .page-schools-list {
        padding-left: 25%;
        background-image: url(/img/cabinet.28de5581.svg);
        background-repeat: no-repeat;
        background-position: 12% center;
    }

    .page-notifications {
      padding-left: 25%;
      background-image: url(/img/notifications.e56a0d7f.svg);
      background-repeat: no-repeat;
      background-position: 12% center;
      background-size: 20px;
    }

    .page-surveys {
      padding-left: 25%;
      background-image: url(/img/surveys.729c33ac.svg);
      background-repeat: no-repeat;
      background-position: 12% center;
      background-size: 20px;
    }

    .page-settings {
        margin-left: 20px;
        padding-left: 25%;
        background-image: url(/img/settings.485a33ca.svg);
        background-repeat: no-repeat;
        background-position: 12% center;
        background-size: 20px;
    }

    .block-title {
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 40px;
        letter-spacing: 0.2px;
        color: #707C97;
    }

    .block-subtitle {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #303030;
    }

    .block-content {
        justify-content: center;
    }

    .cabinet-page-title {
        display: flex;
        justify-content: center;
        font-family: "Roboto Condensed";
        font-style: normal;
        font-weight: normal;
        font-size: 44px;
        line-height: 52px;
        color: #303030;
    }

    .blocks {
        margin-top: 60px;
    }

    .instruction {
        padding: 30px;
        text-align: center;
        line-height: 1px;
        width: 382px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
    }

    .instruction-name {
        font-weight: bold;
    }

    .instruction-subtitle {
        line-height: 19px;
        font-family: "Roboto Condensed";
        font-size: 13px;
        text-align: center;
        color: #000000;
    }

    .video {
        display: flex;
        justify-content: center;
    }

    .close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: #FFFFFF;
        border-color: blue;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
        width: 40px;
        height: 40px;
        bottom: 41px;
        left: 41px;
        border-radius: 50%;
    }


    @media screen and (max-width: 767px) {
        .instruction {
            width: 320px;
        }

        .helps {
            margin-top: 30px;
            margin-bottom: 60px;
            display: flex;
            justify-content: center;
        }

        .video-background {
            width: 285px;
        }

        .cabinet-page-title {
            font-size: 30px;
            display: flex;
            align-items: center;
        }

        .page-block {
            width: 100%;
            margin-top: 30px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
        }

        .blocks {
            margin-top: 0px;
        }

        .page-settings {
            margin-left: 0px;
        }

        .block-subtitle {
            text-align: center;
        }
    }
</style>