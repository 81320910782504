import { render, staticRenderFns } from "./Cabinet.vue?vue&type=template&id=0bd1577a&scoped=true&"
import script from "./Cabinet.vue?vue&type=script&lang=js&"
export * from "./Cabinet.vue?vue&type=script&lang=js&"
import style0 from "./Cabinet.vue?vue&type=style&index=0&id=0bd1577a&scoped=true&lang=css&"
import style1 from "./Cabinet.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd1577a",
  null
  
)

export default component.exports